import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Plyr from './plyr'

export class Player extends Component {

  state = {
    video_src: '',
    controls: !this.props.autoplay,
    loading: false,
    muted: this.props.muted,
  }

  _play = () => {
    this.refs.player.play()
  }

  _rewind = () => {
    this.refs.player.rewind()
  }

  _pause = () => {
    this.refs.player.pause()
  }

  render() {

    let contProps = {
      className: 'r3__video'
    }

    if (this.props.hover) {
      contProps.onMouseOver = (event) => {
        this._play()
      }
      contProps.onMouseLeave = (event) => {
        this._rewind()
      }
    }

    let video

    if (this.props.local) {
      video = (
        <video
          controls={this.props.controls && this.state.controls}
          mediaGroup={this.props.slug}
          muted={this.state.muted}
          loop={this.props.loop}
          autoPlay={this.props.autoplay}
          playsInline
          src={this.props.src}
        />
      )
    } else {
      video = (
        <Plyr
          type='youtube'
          ref='player'
          poster={this.props.video_placeholder}
          loop={ this.props.loop }
          muted={ this.state.muted }
          videoId={ this.props.video_url?.trim() }
          autoplay={ this.props.autoplay }
          urls={{
            youtube: {
              api: 'https://noembed.com/embed?url={0}'
            }
          }}
        />
      )
    }

        //{ !this.state.controls && <div className="r3__video__play" onClick={this._play} /> }
    return (
      <div {...contProps}>
        { video }
        { this.props.showTitle && <h4>{ this.props.title }</h4> }
        { this.state.loading && <div className="r3__video__loading" /> }
      </div>
    );
  }
}

Player.propTypes = {
  video_url: PropTypes.string,
  video_placeholder: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
  showTitle: PropTypes.bool,
  local: PropTypes.bool,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  hover: PropTypes.bool,
};

Player.defaultProps = {
  showTitle: false,
  muted: true,
}

export default Player
